import React from "react";
import { useField } from "formik";
import { InputProps } from "../../types/core";
import './InputField.scss';
// InputField component
const InputField = ({
  id,
  label,
  fieldType,
  name,
  className,
  isDisabled,
  placeholder,
  LabelclassName
}: InputProps) => {
   const [field, meta] = useField(name);
  return (
    <>
     {label &&  <label className={LabelclassName} htmlFor={id || name}>{label}</label>}
      <input
        type={fieldType}
        disabled={isDisabled}
        {...field}
        placeholder={placeholder}
        name={name}
        className={`${className ? className : "input-field"}`}
      />
      {meta.touched && meta.error ? (
        <div className="error mb-4">{meta.error}</div>
      ) : null}
    </>
  );
};

export default InputField;
