import "./AddCoworker.scss";
import { Form, Formik, Field } from "formik";
import Button from "./../../../core/Button/Button";
import SelectField from "../../../core/SelectField/SelectField";
import { useState } from "react";
import { FaUserCog, FaUserTie, FaUserTag, FaUserCheck } from "react-icons/fa";
import { toast } from "react-toastify";
import { ErrorNotification } from "../../../components/ErrorNotification/ErrorNotification";
import InputField from "../../../core/InputField/InputField";
import { createTaskProps } from "../../../types/types";
import { EmployeeRegisterApi } from "../../../services/user.service";

export const AddCoworker = ({ closeModal, recallData }: createTaskProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRoleID, setSelectedRoleID] = useState(1);
    const [selectedRoles, setSelectedRoles] = useState<any>([]);
    let roles:any=[]
  const systemRole = [
    {
      id: 1,
      label: "Admin",
      value: "admin",
      icon: <FaUserCog size={40} className="mb-2" color="#164863"/>,
    },
    {
      id: 2,
      label: "Consultant",
      value: "consultant",
      icon: <FaUserTie size={40} className="mb-2" color="#164863"/>,
    },
    {
      id: 3,
      label: "Job Seeker",
      value: "vendor",
      icon: <FaUserTag size={40} className="mb-2" color="#164863"/>,
    },
    {
      id: 4,
      label: "Employer",
      value: "buyer",
      icon: <FaUserCheck size={40} className="mb-2" color="#164863"/>,
    },
  ];
  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    invitationLanguage: "",
    roles: {
      admin: false,
      consultant: false,
      vendor: false,
      buyer: false,
    },
    manager: "",
  };

  // Handle form submission
  const onSubmit = async (values: any, { setSubmitting }: any) => {
    setIsLoading(true);
    let data={
      email:values.email,
      language:values.invitationLanguage,
      first_name:values.first_name,
      last_name:values.last_name,
      is_admin:selectedRoles.includes(1)?1:0,
      is_consultant:selectedRoles.includes(2)?1:0,
      is_job_seeker:selectedRoles.includes(3)?1:0,
      is_employer:selectedRoles.includes(4)?1:0,
    }

    console.log(data)
    const register_request: any = await EmployeeRegisterApi(data);
    setIsLoading(false);
    if (register_request.status == 201) {
      toast.success("Account created. proceed with email verification process");
      closeModal();
      recallData();
    } else {
      ErrorNotification(register_request.data.message);
    }
  };

  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {(formik) => (
          <Form className="ps-lg-5 pe-lg-5 ps-1 pe-1">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <InputField
                  name="first_name"
                  placeholder=""
                  label={"First Name"}
                  className="register-input mb-4"
                  fieldType="text"
                  LabelclassName="register-lbl"
                />
              </div>
              <div className="col-lg-12 col-md-12 col-12">
                <InputField
                  name="last_name"
                  placeholder=""
                  label={"Last Name"}
                  className="register-input mb-4"
                  fieldType="text"
                  LabelclassName="register-lbl"
                />
              </div>
            </div>
            <InputField
              name="email"
              placeholder=""
              label={"Email"}
              className="register-input mb-3"
              fieldType="email"
              LabelclassName="register-lbl"
            />
            <SelectField
              name="invitationLanguage"
              options={[
                {
                  value: "en",
                  label: "English",
                },
                {
                  value: "se",
                  label: "Sweden",
                },
              ]}
              label={" Invitation Language"}
              className="register-input mb-3"
              LabelclassName="register-lbl"
            />

<div className="mb-3">
  <label>Choose system roles for the coworker</label>
  <div className="container mt-4">
    <div className="row justify-content-start">
      {systemRole.map((role) => (
        <div className="col-3" key={role.id}>
          <div
            className={`role-item position-relative p-3 border border-primary rounded bg-light p-3 ${
              selectedRoles.includes(role.id) ? "active-item" : ""
            }`}
            onClick={() => {
              if (selectedRoles.includes(role.id)) {
                // If the role is already selected, remove it
                setSelectedRoles(selectedRoles.filter((r:any) => r !== role.id));
              } else {
                // Otherwise, add it
                setSelectedRoles([...selectedRoles, role.id]);
              }
            }}
          >
            <input
              type="checkbox"
              checked={selectedRoles.includes(role.id)}
              className="form-check-input position-absolute"
            />

            <label
              htmlFor="admin"
              className="d-flex flex-column align-items-center justify-content-center h-100"
            >
              {role.icon}
              <div>{role.label}</div>
            </label>
          </div>
        </div>
      ))}
    </div>
  </div>
</div>


            {/* <SelectField
              name="manager"
              options={[
                {
                  value: "manager1",
                  label: "Manager1",
                },
                {
                  value: "manager2",
                  label: "Manager2",
                },
                {
                  value: "manager3",
                  label: "Manager3",
                },
              ]}
              label={"Manager"}
              className="register-input mb-3"
              LabelclassName="register-lbl"
            /> */}

            <div className="d-flex justify-content-center mt-5 pb-lg-0 pb-4">
              <Button
                className="register-btn"
                buttonText={"Add Co-worker"}
                type="submit"
                isLoading={isLoading}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
