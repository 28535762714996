import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsList } from "react-icons/bs";
import { HeaderProps } from "../../types/types";
import { useWebSocket } from "../../context/webSocketContext";
import { SearchBar } from "../SearchBar/SearchBar";
import ImageComponent from "../../core/Image/Image";
import profile from "../../assets/profile.png";
import "./Header.scss";

const Header: React.FC<HeaderProps> = ({ user }) => {
  const { messages } = useWebSocket();
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState<any[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const e_userType = localStorage.getItem("e_type");
  const j_userType = localStorage.getItem("j_type");
  const isCompany = localStorage.getItem("isadmin") === "comp";

  useEffect(() => {
    if (messages?.data) {
      try {
        const parsedMessage = JSON.parse(messages.data);
        setNotifications(prev => [...prev, parsedMessage]);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
  }, [messages?.data]);

  const toggleSidebar = () => setIsOpen(!isOpen);

  const renderMenuItem = (label: string, path: string, isMobile = false) => (
    <div
      key={path}
      className={`menu-option p-4 ${isMobile ? 'menu2 pb-2' : 'hide-mobile'}`}
      onClick={() => navigate(path)}
    >
      {label}
    </div>
  );

  const renderMenuItems = (isMobile = false) => {
    const items = [];
    if (e_userType === "1") {
      items.push(renderMenuItem("My Tasks", "/task-management", isMobile));
    }
    if (j_userType === "1") {
      items.push(
        renderMenuItem("Find Bid", "/find-bids", isMobile),
        renderMenuItem("My Bids", "/bid-management", isMobile)
      );
    } 


    if (isCompany) {
      // items.push(renderMenuItem("User Management", "/user-management", isMobile));
      items.push(renderMenuItem("Co-workers", "/coworkers", isMobile));
      items.push(renderMenuItem("Connections", "/connections", isMobile));
    }

    items.push(renderMenuItem("Chat", "/chat", isMobile));

    return items;
  };

  return (
    <div className="d-flex justify-content-between">
      <div className="d-flex">
        <div className="pt-3 hide-desktop" onClick={toggleSidebar}>
          <BsList style={{ fontSize: '1.5rem' }} />
        </div>
        <div className="header-logo p-3" onClick={() => navigate("/")}>
          Sparetan
        </div>
        {renderMenuItems()}
      </div>

      <div className="d-flex pt-4 pe-4 align-items-center">
        <div className="pe-4 hide-mobile">
          <SearchBar widthClass="search-size" />
        </div>
        <NotificationBell notifications={notifications} />
        <ProfileDropdown />
      </div>

      <nav className={`sidebar ${isOpen ? 'sidebar-open' : ''}`}>
        {renderMenuItems(true)}
      </nav>
    </div>
  );
};

const NotificationBell: React.FC<{ notifications: any[] }> = ({ notifications }) => {
  const navigate = useNavigate();

  return (
    <div className="dropdown">
      <button
        type="button"
        id="dropdownMenuButton2"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        className="btn btn-link p-0"
      >
        <i className="bi bi-bell pe-4">
          {notifications.length > 0 && (
            <span className="badge bg-secondary rounded-pill">
              {notifications.length}
            </span>
          )}
        </i>
      </button>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton2">
        {notifications.map((notify, index) => (
          <li key={index}>
            <div className="dropdown-item" onClick={() => navigate("/chat/")}>
              You have a new message
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

const ProfileDropdown: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="dropdown">
      <button
        className="btn btn-link dropdown-toggle p-0"
        type="button"
        id="dropdownMenuButton"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <ImageComponent src={profile} alt="Profile" />
      </button>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <li>
          <div className="dropdown-item" onClick={() => navigate("/profile")}>
            Profile
          </div>
        </li>
        <li>
          <div className="dropdown-item" onClick={() => navigate("/login")}>
            Logout
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Header;
