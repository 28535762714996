import { useEffect, useState } from "react";
import { SearchBarClear } from "../../components/SearchBarWithClear/SearchBarClear";
import "./Coworkers.scss";
import Button from "../../core/Button/Button";
import { Form, Formik } from "formik";
import SelectField from "../../core/SelectField/SelectField";
import CustomModal from "../../components/Modal/Modal";
import { BsPlusLg, BsPencil } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import {
  getAllUserDetailsApi,
  UserSummeryApi,
} from "../../services/user.service";
import { ErrorNotification } from "../../components/ErrorNotification/ErrorNotification";
import { UserListBar } from "../../components/UserListBar/UserListBar";
import { AddCoworker } from "./AddCoworker/AddCoworker";
import { useNavigate } from "react-router-dom";

const data: any = [];

export const Coworkers = () => {
  const navigate = useNavigate();
  const [employeeDetails, setEmployeeDetails] = useState<any>(data);
  const [searchValue, setSearchValue] = useState("");
  const [viewCoworkerShow, setViewCoworkerModalShow] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [manager, setManager] = useState("");
  const [status, setStatus] = useState("");
  const [isLaoding, setIsLaoding] = useState(true);

  const [userSummeryList, setUserSummeryList] = useState<any>([]);

  useEffect(() => {
    getUSerSummeryDetails();
  }, []);

  const getUSerSummeryDetails = async () => {
    try {
      const user_details: any = await UserSummeryApi("1", "", "");

      if (user_details.status === 200) {
        setUserSummeryList(user_details.data);
      } else {
        ErrorNotification(user_details.message);
      }
    } catch (error) {
      ErrorNotification("Error fetching user details");
    }
  };

  const toggleViewCoworkerModal = () =>
    setViewCoworkerModalShow(!viewCoworkerShow);

  const initialValues = {
    filter_name: "",
    filter_email: "",
    filter_role: "",
    filter_manager: "",
    filter_status: "",
  };

  const handleSearchChange = (value: any, field: any) => {
    // setSearchValue(value);
  };

  const getAllUserDetails = async () => {
    setIsLaoding(true);
    try {
      const user_details: any = await getAllUserDetailsApi(
        role,
        manager,
        status,
        name,
        email
      );

      if (user_details.status === 200) {
        setEmployeeDetails(user_details.data);
      } else {
        ErrorNotification(user_details.message);
      }
      setIsLaoding(false);
    } catch (error) {
      setIsLaoding(false);
      ErrorNotification("Error fetching user details");
    }
  };

  useEffect(() => {
    getAllUserDetails();
    console.log(name, email, manager, status, role);
  }, [name, email, manager, status, role]); // Initial load

  return (
    <div className="p-lg-5 p-md-5 p-3">
      {/* {console.log(isLaoding, userSummeryList, "userSummeryList")} */}
      {userSummeryList?.all_workers > 0 ? (
        <div className="row">
          <div className="col-lg-9 col-md-9 col-12">
            <Formik initialValues={initialValues} onSubmit={console.log}>
              {({ handleChange, handleSubmit, values }) => (
                <Form>
                  <div className="row space-between align-items-end">
                    <div className="col-lg-6 col-md-6 col-12">
                      <SearchBarClear
                        widthClass={"search-size3 search-bar-radious"}
                        onChange={setName}
                        placeholder="Name"
                        label="Name"
                        name="name"
                        clearFunction={() => setName("")}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <SearchBarClear
                        widthClass={"search-size3 search-bar-radious"}
                        onChange={setEmail}
                        placeholder="Email"
                        label="Email"
                        name="email"
                        clearFunction={() => setEmail("")}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4">
                      <label>Role</label>
                      <select className="coworker-filter" name="filter_role"  onChange={(e)=>setRole(e.target.value)}>
                      <option value="">Select Role</option>
                        <option value="admin">Admin</option>
                        <option value="consultant">Consultant</option>
                        <option value="job_seeker">Job Seeker</option>
                        <option value="employer">Employer</option>
                      </select>
                      {/* <SelectField
                        options={[
                          { value: "admin", label: "Admin" },
                          { value: "consultant", label: "Consultant" },
                          { value: "job_seeker", label: "Job Seeker" },
                          { value: "employer", label: "Employer" },
                        ]}
                        label={"Role"}
                        className="coworker-filter"
                        name="filter_role"
                        onChange2={setRole}
                      /> */}
                    </div>

                    <div className="col-4">

                      <SelectField
                        options={[
                          { value: "admin", label: "Admin" },
                          { value: "consultant", label: "Consultant" },
                          { value: "job_seeker", label: "Job Seeker" },
                          { value: "employer", label: "Employer" },
                        ]}
                        label={"Manager"}
                        className="coworker-filter"
                        name="filter_manager"
                        onChange2={setManager}
                      />
                    </div>

                    <div className="col-4">

                    <label>Status</label>
                      <select className="coworker-filter" name="filter_status"  onChange={(e)=>setStatus(e.target.value)}>
                      <option value="">Select Role</option>
                        <option value="1">Active</option>
                        <option value="0">In Active</option>
                      </select>
                      {/* <SelectField
                        options={[
                          { value: "0", label: "In Active" },
                          { value: "1", label: "Active" },
                        ]}
                        label={"Status"}
                        className="coworker-filter"
                        name="filter_status"
                        onChange2={setStatus}
                      /> */}
                    </div>
                  </div>
{/* 
                  <Button
                    className="intro-button mt-5"
                    buttonText="Search"
                    onClickHandler={handleSubmit}
                  /> */}
                </Form>
              )}
            </Formik>

            <div className="d-flex justify-content-end">
              <Button
                className="intro-button mt-5"
                buttonText="Add Coworker"
                icon={<BsPlusLg />}
                onClickHandler={toggleViewCoworkerModal}
              />
            </div>

            <div className="pb-5 pt-5">
              {employeeDetails && employeeDetails.length > 0 ? (
                <table className="table table-stripes">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">E-mail</th>
                      <th scope="col">Role</th>
                      <th scope="col">Manager</th>
                      <th scope="col">Status</th>
                      <th scope="col"></th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {employeeDetails.map((employee: any) => (
                      <tr key={employee.id}>
                        <td>
                          {employee.first_name} {employee.last_name}
                        </td>
                        <td>{employee.email}</td>
                        <td>
                          {employee.is_employer ? "Employer" : "Job Seeker"}
                        </td>
                        <td>{employee.manager_name || "N/A"}</td>
                        <td>
                          {employee.is_active === 1 ? (
                            <div className="active-badge d-flex justify-content-around align-items-center">
                              <span className="active-dot"></span>
                              <span>Active</span>
                            </div>
                          ) : (
                            <div className="inactive-badge d-flex justify-content-around align-items-center">
                              <span className="inactive-dot"></span>
                              <span>In-Active</span>
                            </div>
                          )}
                        </td>
                        <td className="d-flex">
                          <div className="employee-btn" onClick={()=>navigate("/view-profile/"+employee.id)}>View Profile</div>
                        </td>
                        {/* <td>
                          <BsPencil size={20} className="text-secondary" />
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="p-lg-5 p-md-5 p-3">
                  <div className="container text-center mt-5">
                    <div className="row">
                      <div className="col">
                        <div className="p-5 bg-light border rounded">
                          <h5 className="mb-4">
                            Searched Co-worker not found !
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <UserListBar
            employeeUserList={userSummeryList}
            title="Don’t see your coworker?"
            onClick ={toggleViewCoworkerModal}
          />
        </div>
      ) : (
        <div className="p-lg-5 p-md-5 p-3">
          <div className="container text-center mt-5">
            <div className="row">
              <div className="col">
                <div className="p-5 bg-light border rounded">
                  <h5 className="mb-4">
                    You haven’t connected with any connectors or consultants
                    yet. YOu might find your next star right here. Expand your
                    network by connecting with coworkers who are already using
                    the platform or invite new colleagues to join you.
                    Collaborate, share tasks, and build stronger connections
                    within your professional community.
                  </h5>
                  <div className="text-center mt-5">
                    <Button
                      className="co-work-search me-2 search-btn p-10"
                      buttonText="Search connections"
                      icon={<FaSearch />}
                    />
                    <Button
                      className="co-work-add me-2 search-btn p-10"
                      buttonText="Send Invitaion"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <CustomModal
        show={viewCoworkerShow}
        toggle={toggleViewCoworkerModal}
        ModalHeader="Add Coworker"
        size={"lg"}
      >
        <AddCoworker
          closeModal={toggleViewCoworkerModal}
          recallData={() => getAllUserDetails()}
        />
      </CustomModal>
    </div>
  );
};
