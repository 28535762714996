export const remote_url_v1 = "https://api-prod-main.sparetan.com/api/v1/"

export const remote_chat_url_v1 = "wss://api-prod-chat.sparetan.com/"

export const remote_faceid_url_v1 = "https://api-prod-faceid.sparetan.com/"

// export const remote_url_v1 = "http://95.216.206.214:8000/api/v1/"

// export const remote_chat_url_v1 = "ws://95.216.206.214:8001/"

// export const remote_faceid_url_v1 = "http://95.216.206.214:8000/"