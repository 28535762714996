import { useEffect, useState } from "react";
import { ErrorNotification } from "../../../components/ErrorNotification/ErrorNotification";
import { getSubmissionApi } from "../../../services/submission.service";
import "./SubmissionHistory.scss";
import { ViewAttachment } from "../../../components/ViewAttachment/ViewAttachment";

export const SubmissionHistory = ({ id }: any) => {
  const [submissions, setSubmissions] = useState([]);
  useEffect(() => {
    getAllSubmissions();
  }, []);

  const getAllSubmissions = async () => {
    const tasks: any = await getSubmissionApi(id.id);

    if (tasks.status == 200) {
      setSubmissions(tasks.data.data);
    } else {
      ErrorNotification(tasks.message);
    }
  };
  return (
    <div>
      {submissions &&
        submissions.map((submit: any) => (
          <div className="p-3 mt-4 submit-bg">
            <div className="submission-time pb-3">{submit.created_on}</div>
            <div className="complete-time pb-1">Task Completion Time</div>
            <div className="pb-3">6 hours</div>
            <div className="label mb-3">Description</div>
            <div>
              <textarea
                className="task-input-desc mb-4"
                value={submit.description}
              ></textarea>
            </div>
            <div className="status-lbl">Submissions</div>

<ViewAttachment attachments={submit.subtask_files} />


<div className="status-lbl pt-5">Invoices</div>

<ViewAttachment attachments={submit.sub_task_invoice} />
</div>
        ))}

      {submissions && submissions.length == 0 && (
        <div className="no-bids submit-bg p-3 mt-4 ">
          No previous submissions available
        </div>
      )}
    </div>
  );
};
